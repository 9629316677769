<template>
    <!-- Hero -->
    <div class="relative isolate "> <!-- removed isolate-->
        <div class="py-24 sm:py-24 lg:pb-40">
            <div class="mx-auto max-w-7xl px-6 lg:px-8">
                <div class="text-center">
                    <h1 class="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                        Automate Your Business with Custom AI Agents
                    </h1>
                    <p class="mt-6 text-lg leading-8 text-gray-600">
                        Tailored AI solutions to streamline your operations and boost productivity.
                    </p>
                    <div class="mt-10 flex items-center justify-center gap-x-6">
                        <a
                            class="rounded-md bg-slate-900 py-3 sm:py-4 px-8 sm:px-12 text-md font-semibold text-white shadow-sm hover:bg-slate-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:bg-slate-800 lightbox-242710848738061">
                            Share Your Automation Idea
                        </a>
                        <!-- <a href="#" class="text-sm font-semibold leading-6 text-gray-900">
                            Watch Demo <span aria-hidden="true">→</span> 
                        </a>-->
                    </div>
                </div>

                <!-- Hero Image 
                <div class="mt-16 flow-root sm:mt-24">
                    <div
                        class="-m-2 rounded-xl bg-gray-900/5 p-2 ring-1 ring-inset ring-gray-900/10 lg:-m-4 lg:rounded-2xl lg:p-4">
                        <img src="../assets/hero-image.svg" alt="App screenshot" width="2432" height="1442"
                            class="rounded-md shadow-2xl ring-1 ring-gray-900/10">
                    </div>
                </div>
                 -->
            </div>
        </div>
        <div class="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
            aria-hidden="true">
            <div class="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
                style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)">
            </div>
        </div>
    </div>
    <div class="absolute inset-x-0 -top-20 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-40" aria-hidden="true">
        <div class="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)">
        </div>
    </div>
    <!-- Logos 
    <div class="bg-white py-24 sm:py-24">
        <div class="mx-auto max-w-7xl px-6 lg:px-8">
            <h2 class="text-center text-lg font-semibold leading-8 text-gray-900">Trusted by the marketing and analytics teams
            </h2>
            <div
                class="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5">
                <img class="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                    src="../assets/client_logos/plann.svg" alt="Plann" width="158" height="48">
                <img class="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                    src="../assets/client_logos/petzyo.svg" alt="Petzyo" width="158" height="48">
                <img class="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                    src="../assets/client_logos/gopure.svg" alt="GoPure" width="158" height="48">
                <img class="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1"
                    src="../assets/client_logos/piri.svg" alt="Piri Red" width="158" height="48">
                <img class="col-span-2 col-start-2 max-h-12 w-full object-contain sm:col-start-auto lg:col-span-1"
                    src="../assets/client_logos/publicgoods.png" alt="Public Goods" width="158" height="48">
            </div>
        </div>
    </div>
    -->


    <!-- Why we built Vendo-->
    <div class="relative isolate overflow-hidden bg-gray-900 py-24 sm:py-32">
        <img src="https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2850&q=80&blend=111827&blend-mode=multiply&sat=-100&exp=15"
            alt="" class="absolute inset-0 -z-10 h-full w-full object-cover">
        <div class="relative mx-auto max-w-7xl px-6 lg:px-8">
            <div class="absolute -bottom-8 -left-96 -z-10 transform-gpu blur-3xl sm:-bottom-64 sm:-left-40 lg:-bottom-32 lg:left-8 xl:-left-10"
                aria-hidden="true">
                <div class="aspect-[1266/975] w-[79.125rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
                    style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)">
                </div>
            </div>
            <div class="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl">
                <h2 class="text-base font-semibold leading-8 text-indigo-400">Who We Are</h2>
                <p class="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
                    AI Business Process Automation Company
                </p>
                <p class="mt-6 text-lg leading-8 text-gray-300">
                    We are a specialized AI consulting firm dedicated to helping businesses
                    automate their processes through custom-built AI agents. Our team
                    collaborates with you to understand your unique challenges and develops
                    AI solutions that integrate seamlessly into your workflow.
                </p>
            </div>
        </div>
    </div>


    <!-- Features -->
    <div class="relative isolate overflow-hidden bg-slate-100 px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
        <div class="absolute inset-0 -z-10 overflow-hidden">
            <svg class="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
                aria-hidden="true">
                <defs>
                    <pattern id="e813992c-7d03-4cc4-a2bd-151760b470a0" width="200" height="200" x="50%" y="-1"
                        patternUnits="userSpaceOnUse">
                        <path d="M100 200V.5M.5 .5H200" fill="none" />
                    </pattern>
                </defs>
                <svg x="50%" y="-1" class="overflow-visible fill-slate-200">
                    <path
                        d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                        stroke-width="0" />
                </svg>
                <rect width="100%" height="100%" stroke-width="0" fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)" />
            </svg>
        </div>
        <div
            class="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
            <div
                class="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
                <div class="lg:pr-4">
                    <div class="lg:max-w-lg">
                        <p class="text-base font-semibold leading-7 text-indigo-600">Our Approach</p>
                        <h2 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                            Why Use AI
                        </h2>
                        <p class="mt-6 text-xl leading-8 text-gray-700">
                            At our consultancy, we leverage AI to transform and optimize business processes, making
                            operations
                            more efficient and data-driven. We integrate advanced AI tools to automate repetitive tasks,
                            enhance decision-making through predictive analytics, and uncover hidden insights within
                            complex data sets.
                        </p>
                    </div>
                </div>
            </div>
            <div
                class="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
                <img class="w-[48rem] max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
                    src="../assets/ai-unsplash.jpg" alt="">
            </div>
            <div
                class="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
                <div class="lg:pr-4">
                    <div class="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
                        <ul role="list" class="space-y-8 text-gray-600">
                            <li class="flex gap-x-3">
                                <svg class="mt-1 h-5 w-5 flex-none text-indigo-600" xmlns="http://www.w3.org/2000/svg"
                                    fill="none" viewBox="0 0 24 24" stroke-width="1.7" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="M12 9.75v6.75m0 0-3-3m3 3 3-3m-8.25 6a4.5 4.5 0 0 1-1.41-8.775 5.25 5.25 0 0 1 10.233-2.33 3 3 0 0 1 3.758 3.848A3.752 3.752 0 0 1 18 19.5H6.75Z" />
                                </svg>
                                <span><strong class="font-semibold text-gray-900">Increase Speed</strong> Accelerate
                                    business processes and decision-making with intelligent automation.</span>
                            </li>
                            <li class="flex gap-x-3">
                                <svg class="mt-1 h-5 w-5 flex-none text-indigo-600" xmlns="http://www.w3.org/2000/svg"
                                    fill="none" viewBox="0 0 24 24" stroke-width="1.7" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="M12 9.75v6.75m0 0-3-3m3 3 3-3m-8.25 6a4.5 4.5 0 0 1-1.41-8.775 5.25 5.25 0 0 1 10.233-2.33 3 3 0 0 1 3.758 3.848A3.752 3.752 0 0 1 18 19.5H6.75Z" />
                                </svg>
                                <span><strong class="font-semibold text-gray-900">Reduce Costs</strong> Lower
                                    operational expenses by automating repetitive and time-consuming tasks.</span>
                            </li>
                            <li class="flex gap-x-3">
                                <svg class="mt-1 h-5 w-5 flex-none text-indigo-600" xmlns="http://www.w3.org/2000/svg"
                                    fill="none" viewBox="0 0 24 24" stroke-width="1.7" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="M12 9.75v6.75m0 0-3-3m3 3 3-3m-8.25 6a4.5 4.5 0 0 1-1.41-8.775 5.25 5.25 0 0 1 10.233-2.33 3 3 0 0 1 3.758 3.848A3.752 3.752 0 0 1 18 19.5H6.75Z" />
                                </svg>
                                <span><strong class="font-semibold text-gray-900">Stay Ahead of the Competition</strong>
                                    Leverage cutting-edge AI technology to gain a competitive edge in your
                                    industry.</span>
                            </li>
                            <li class="flex gap-x-3">
                                <svg class="mt-1 h-5 w-5 flex-none text-indigo-600" xmlns="http://www.w3.org/2000/svg"
                                    fill="none" viewBox="0 0 24 24" stroke-width="1.7" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="M12 9.75v6.75m0 0-3-3m3 3 3-3m-8.25 6a4.5 4.5 0 0 1-1.41-8.775 5.25 5.25 0 0 1 10.233-2.33 3 3 0 0 1 3.758 3.848A3.752 3.752 0 0 1 18 19.5H6.75Z" />
                                </svg>
                                <span><strong class="font-semibold text-gray-900">Enhance Productivity</strong> Free up
                                    your team to focus on strategic initiatives rather than manual tasks.</span>
                            </li>
                            <li class="flex gap-x-3">
                                <svg class="mt-1 h-5 w-5 flex-none text-indigo-600" xmlns="http://www.w3.org/2000/svg"
                                    fill="none" viewBox="0 0 24 24" stroke-width="1.7" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="M12 9.75v6.75m0 0-3-3m3 3 3-3m-8.25 6a4.5 4.5 0 0 1-1.41-8.775 5.25 5.25 0 0 1 10.233-2.33 3 3 0 0 1 3.758 3.848A3.752 3.752 0 0 1 18 19.5H6.75Z" />
                                </svg>
                                <span><strong class="font-semibold text-gray-900">Take Advantage of AI
                                        Innovation</strong> Harness the latest advancements in AI to optimize your
                                    operations.</span>
                            </li>
                            <li class="flex gap-x-3">
                                <svg class="mt-1 h-5 w-5 flex-none text-indigo-600" xmlns="http://www.w3.org/2000/svg"
                                    fill="none" viewBox="0 0 24 24" stroke-width="1.7" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="M12 9.75v6.75m0 0-3-3m3 3 3-3m-8.25 6a4.5 4.5 0 0 1-1.41-8.775 5.25 5.25 0 0 1 10.233-2.33 3 3 0 0 1 3.758 3.848A3.752 3.752 0 0 1 18 19.5H6.75Z" />
                                </svg>
                                <span><strong class="font-semibold text-gray-900">Improve Accuracy</strong> Minimize
                                    human errors with precise and consistent AI-driven solutions.</span>
                            </li>
                            <li class="flex gap-x-3">
                                <svg class="mt-1 h-5 w-5 flex-none text-indigo-600" xmlns="http://www.w3.org/2000/svg"
                                    fill="none" viewBox="0 0 24 24" stroke-width="1.7" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="M12 9.75v6.75m0 0-3-3m3 3 3-3m-8.25 6a4.5 4.5 0 0 1-1.41-8.775 5.25 5.25 0 0 1 10.233-2.33 3 3 0 0 1 3.758 3.848A3.752 3.752 0 0 1 18 19.5H6.75Z" />
                                </svg>
                                <span><strong class="font-semibold text-gray-900">Scalable Solutions</strong> Implement
                                    AI systems that grow and adapt with your business needs.</span>
                            </li>
                        </ul>
                        <!--<p class="mt-8">Et vitae blandit facilisi magna lacus commodo. Vitae sapien duis odio id et. Id blandit molestie auctor fermentum dignissim. Lacus diam tincidunt ac cursus in vel. Mauris varius vulputate et ultrices hac adipiscing egestas. Iaculis convallis ac tempor et ut. Ac lorem vel integer orci.</p> -->
                        <h2 class="mt-16 text-2xl font-bold tracking-tight text-gray-900"></h2>
                        <p class="mt-6">
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Features -->
    <div class="bg-white py-24 sm:py-32">
        <div class="mx-auto max-w-7xl px-6 lg:px-8">
            <div
                class="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                <div>
                    <h2 class="text-base font-semibold leading-7 text-indigo-600">Discover Use Cases</h2>
                    <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">How AI agents can
                        transform various aspects of your business?</p>
                    <p class="mt-6 text-base leading-7 text-gray-600">
                        By employing AI-powered solutions, we help businesses streamline workflows, reduce costs, and
                        identify
                        growth opportunities. <br><br>
                        Our team collaborates closely with clients to tailor AI applications to their
                        unique needs, ensuring seamless integration and impactful results that drive sustainable
                        success.
                    </p>
                </div>
                <dl
                    class="col-span-2 grid grid-cols-1 gap-x-8 gap-y-10 text-base leading-7 text-gray-600 sm:grid-cols-2 lg:gap-y-16">
                    <div v-for="feature in features" :key="feature.name" class="relative pl-9">
                        <dt class="font-semibold text-gray-900">
                            <CheckIcon class="absolute left-0 top-1 h-5 w-5 text-indigo-500" aria-hidden="true" />
                            {{ feature.name }}
                        </dt>
                        <dd class="mt-2">{{ feature.description }}</dd>
                    </div>
                </dl>
            </div>
        </div>
    </div>

    <!-- Services -->
    <div class="bg-gray-900 py-24 sm:py-32">
        <div class="mx-auto max-w-7xl px-6 lg:px-8">

            <div class="mx-auto max-w-2xl lg:text-center">
                <h2 class="text-base font-semibold leading-7 text-indigo-400">Our Services</h2>
                <p class="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
                    Automations Designed For Your Business</p>
                <p class="mt-6 text-lg leading-8 text-gray-300">
                    Based on your needs, we create an AI agent using stacks of different AI solutions.
                    We combine these technologies to build your unique AI agent, ensuring it addresses
                    your specific business challenges effectively.
                </p>
            </div>
            <div class="grid gap-8 sm:grid-cols-2 lg:grid-cols-3 py-12">
                <div class="flex flex-col items-center space-y-4 text-center">
                    <div class="p-4 bg-blue-100 rounded-full">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="size-6">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                        </svg>

                    </div>
                    <h3 class="text-xl font-bold text-white">Process Analysis</h3>
                    <p class="text-gray-300">Identifying areas in your operations that can benefit from automation.</p>
                </div>
                <div class="flex flex-col items-center space-y-4 text-center">
                    <div class="p-4 bg-blue-100 rounded-full">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="size-6">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="m6.75 7.5 3 2.25-3 2.25m4.5 0h3m-9 8.25h13.5A2.25 2.25 0 0 0 21 18V6a2.25 2.25 0 0 0-2.25-2.25H5.25A2.25 2.25 0 0 0 3 6v12a2.25 2.25 0 0 0 2.25 2.25Z" />
                        </svg>

                    </div>
                    <h3 class="text-xl font-bold text-white">Custom AI Development</h3>
                    <p class="text-gray-300">Designing AI agents tailored to your specific needs.</p>
                </div>
                <div class="flex flex-col items-center space-y-4 text-center">
                    <div class="p-4 bg-blue-100 rounded-full">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="size-6">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="M8.625 9.75a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 0 1 .778-.332 48.294 48.294 0 0 0 5.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z" />
                        </svg>
                    </div>
                    <h3 class="text-xl font-bold text-white">Integration &amp; Support</h3>
                    <p class="text-gray-300">Implementing solutions and providing ongoing support.</p>
                </div>
            </div>
        </div>
    </div>


    <!-- Our Technology -->

    <div class="bg-gray-50 py-24 sm:py-32">
        <div class="mx-auto max-w-2xl px-6 lg:max-w-7xl lg:px-8">
            <h2 class="text-center text-base/7 font-semibold text-indigo-600">Our Technology</h2>
            <p
                class="mx-auto mt-2 max-w-lg text-pretty text-center text-4xl font-medium tracking-tight text-gray-950 sm:text-5xl">
                Build for Scale</p>
            <div class="mt-10 grid gap-4 sm:mt-16 lg:grid-cols-3 lg:grid-rows-2">
                <div class="relative lg:row-span-2">
                    <div class="absolute inset-px rounded-lg bg-white lg:rounded-l-[2rem]" />
                    <div
                        class="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] lg:rounded-l-[calc(2rem+1px)]">
                        <div class="px-8 pb-3 pt-8 sm:px-10 sm:pb-0 sm:pt-10">
                            <p class="mt-2 text-lg/7 font-medium tracking-tight text-gray-950 max-lg:text-center">
                                Realtime Notifications</p>
                            <p class="mt-2 max-w-lg text-sm/6 text-gray-600 max-lg:text-center">
                                Get notifications of your automations processed in real time
                            </p>
                        </div>
                        <div
                            class="relative min-h-[30rem] w-full grow [container-type:inline-size] max-lg:mx-auto max-lg:max-w-sm">
                            <div
                                class="absolute inset-x-10 bottom-0 top-10 overflow-hidden rounded-t-[12cqw] border-x-[3cqw] border-t-[3cqw] border-gray-700 bg-gray-900 shadow-2xl">
                                <img class="size-full object-cover object-top"
                                    src="https://tailwindui.com/plus/img/component-images/bento-03-mobile-friendly.png"
                                    alt="" />
                            </div>
                        </div>
                    </div>
                    <div
                        class="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 lg:rounded-l-[2rem]" />
                </div>
                <div class="relative max-lg:row-start-1">
                    <div class="absolute inset-px rounded-lg bg-white max-lg:rounded-t-[2rem]" />
                    <div
                        class="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] max-lg:rounded-t-[calc(2rem+1px)]">
                        <div class="px-8 pt-8 sm:px-10 sm:pt-10">
                            <p class="mt-2 text-lg/7 font-medium tracking-tight text-gray-950 max-lg:text-center">
                                Enterprise Performance</p>
                            <p class="mt-2 max-w-lg text-sm/6 text-gray-600 max-lg:text-center">
                                X can process up to 1,000,000 automations in real time.
                            </p>
                        </div>
                        <div
                            class="flex flex-1 items-center justify-center px-8 max-lg:pb-12 max-lg:pt-10 sm:px-10 lg:pb-2">
                            <img class="w-full max-lg:max-w-xs"
                                src="https://tailwindui.com/plus/img/component-images/bento-03-performance.png"
                                alt="" />
                        </div>
                    </div>
                    <div
                        class="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 max-lg:rounded-t-[2rem]" />
                </div>
                <div class="relative max-lg:row-start-3 lg:col-start-2 lg:row-start-2">
                    <div class="absolute inset-px rounded-lg bg-white" />
                    <div
                        class="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)]">
                        <div class="px-8 pt-8 sm:px-10 sm:pt-10">
                            <p class="mt-2 text-lg/7 font-medium tracking-tight text-gray-950 max-lg:text-center">
                                Security</p>
                            <p class="mt-2 max-w-lg text-sm/6 text-gray-600 max-lg:text-center">
                                Your data is encrypted using industry best practices.
                            </p>
                        </div>
                        <div class="flex flex-1 items-center [container-type:inline-size] max-lg:py-6 lg:pb-2">
                            <img class="h-[min(152px,40cqw)] object-cover object-center"
                                src="https://tailwindui.com/plus/img/component-images/bento-03-security.png" alt="" />
                        </div>
                    </div>
                    <div class="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5" />
                </div>
                <div class="relative lg:row-span-2">
                    <div class="absolute inset-px rounded-lg bg-white max-lg:rounded-b-[2rem] lg:rounded-r-[2rem]" />
                    <div
                        class="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] max-lg:rounded-b-[calc(2rem+1px)] lg:rounded-r-[calc(2rem+1px)]">
                        <div class="px-8 pb-3 pt-8 sm:px-10 sm:pb-0 sm:pt-10">
                            <p class="mt-2 text-lg/7 font-medium tracking-tight text-gray-950 max-lg:text-center">
                                Integrated to your workflows</p>
                            <p class="mt-2 max-w-lg text-sm/6 text-gray-600 max-lg:text-center">
                                No need to jump between apps, Zap Stream can integrate to your existing tools.</p>
                        </div>
                        <div class="relative min-h-[30rem] w-full grow">
                            <div
                                class="absolute bottom-0 left-10 right-0 top-10 overflow-hidden rounded-tl-xl bg-gray-900 shadow-2xl">
                                <div class="flex bg-gray-800/40 ring-1 ring-white/5">
                                    <div class="-mb-px flex text-sm font-medium leading-6 text-gray-400">
                                        <div
                                            class="border-b border-r border-b-white/20 border-r-white/10 bg-white/5 px-4 py-2 text-white">
                                            Automations.jsx</div>
                                        <div class="border-r border-gray-600/10 px-4 py-2">Business_Logic.jsx</div>
                                    </div>
                                </div>
                                <div class="px-6 pb-14 pt-6 text-white">
                                    <p class="code-block">
                                        1. Processing Emails [Gmail] <br>
                                        2. Updating CRM [Hubspot] <br>
                                        3. Creating Proposals [Docs] <br>
                                        4. Pending Approvals<br>
                                        5. Sending Proposals [Gmail] <br>
                                    </p>                      

                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 max-lg:rounded-b-[2rem] lg:rounded-r-[2rem]" />
                </div>
            </div>
        </div>
    </div>

    <!-- How it works-->
    <div class="overflow-hidden bg-white py-24 sm:py-32">
        <div class="mx-auto max-w-7xl px-6 lg:px-8">
            <div
                class="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                <div class="lg:ml-auto lg:pl-4 lg:pt-4">
                    <div class="lg:max-w-lg">
                        <h2 class="text-base font-semibold leading-7 text-indigo-600">Our Process</h2>
                        <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">How It Works</p>
                        <!--  <p class="mt-6 text-lg leading-8 text-gray-600">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.</p>
            -->
                        <div>
                            <ol role="list" class="overflow-hidden py-12">
                                <li v-for="(step, stepIdx) in steps" :key="step.name"
                                    :class="[stepIdx !== steps.length - 1 ? 'pb-10' : '', 'relative']">
                                    <template v-if="step.status === 'complete'">
                                        <div v-if="stepIdx !== steps.length - 1"
                                            class="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-indigo-600"
                                            aria-hidden="true" />
                                        <div class="group relative flex items-start">
                                            <span class="flex h-9 items-center">
                                                <span
                                                    class="relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-indigo-600 group-hover:bg-indigo-800">
                                                    <CheckIcon class="h-5 w-5 text-white" aria-hidden="true" />
                                                </span>
                                            </span>
                                            <span class="ml-4 flex min-w-0 flex-col">
                                                <span class="text-sm font-medium">{{ step.name }}</span>
                                                <span class="text-sm text-gray-500">{{ step.description }}</span>
                                            </span>
                                        </div>
                                    </template>
                                    <template v-else-if="step.status === 'current'">
                                        <div v-if="stepIdx !== steps.length - 1"
                                            class="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300"
                                            aria-hidden="true" />
                                        <div class="group relative flex items-start" aria-current="step">
                                            <span class="flex h-9 items-center" aria-hidden="true">
                                                <span
                                                    class="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-indigo-600 bg-white">
                                                    <span class="h-2.5 w-2.5 rounded-full bg-indigo-600" />
                                                </span>
                                            </span>
                                            <span class="ml-4 flex min-w-0 flex-col">
                                                <span class="text-sm font-medium text-indigo-600">{{ step.name }}</span>
                                                <span class="text-sm text-gray-500">{{ step.description }}</span>
                                            </span>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div v-if="stepIdx !== steps.length - 1"
                                            class="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300"
                                            aria-hidden="true" />
                                        <div class="group relative flex items-start">
                                            <span class="flex h-9 items-center" aria-hidden="true">
                                                <span
                                                    class="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white group-hover:border-gray-400">
                                                    <span
                                                        class="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300" />
                                                </span>
                                            </span>
                                            <span class="ml-4 flex min-w-0 flex-col">
                                                <span class="text-sm font-medium text-gray-500">{{ step.name }}</span>
                                                <span class="text-sm text-gray-500">{{ step.description }}</span>
                                            </span>
                                        </div>
                                    </template>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div class="flex items-start justify-end lg:order-first">
                    <img src="../assets/Leonardo_Kino_XL_Create_a_consultant_that_is_holding_a_project_0.jpg"
                        alt="Product screenshot"
                        class="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
                        width="2432" height="1442" />
                </div>
            </div>
        </div>
    </div>

    <!-- Testimonial -->

    <section class="bg-gray-900 py-24 sm:py-32">
        <div class="mx-auto max-w-7xl px-6 lg:px-8">
            <div class="mx-auto grid max-w-2xl grid-cols-1 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                <div class="flex flex-col pb-10 sm:pb-16 lg:pb-0 lg:pr-8 xl:pr-20">
                    <img class="h-12 self-start" src="https://tailwindui.com/img/logos/tuple-logo-white.svg" alt="" />
                    <figure class="mt-10 flex flex-auto flex-col justify-between">
                        <blockquote class="text-lg leading-8 text-white">
                            <p>“Thanks to Zap Stream, our invoice processing automations have significantly improved,
                                saving
                                us 10 hours per week. This has allowed us to focus more on strategic tasks.”</p>
                        </blockquote>
                        <figcaption class="mt-10 flex items-center gap-x-6">
                            <img class="h-14 w-14 rounded-full bg-gray-800"
                                src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                alt="" />
                            <div class="text-base">
                                <div class="font-semibold text-white">Judith Black</div>
                                <div class="mt-1 text-gray-400">CEO of Tuple</div>
                            </div>
                        </figcaption>
                    </figure>
                </div>
                <div
                    class="flex flex-col border-t border-white/10 pt-10 sm:pt-16 lg:border-l lg:border-t-0 lg:pl-8 lg:pt-0 xl:pl-20">
                    <img class="h-12 self-start" src="https://tailwindui.com/img/logos/reform-logo-white.svg" alt="" />
                    <figure class="mt-10 flex flex-auto flex-col justify-between">
                        <blockquote class="text-lg leading-8 text-white">
                            <p>“Zap Stream has revolutionized our proposal creation process, saving us 14 hours per
                                week.
                                This efficiency boost has been a game-changer for our team.”</p>
                        </blockquote>
                        <figcaption class="mt-10 flex items-center gap-x-6">
                            <img class="h-14 w-14 rounded-full bg-gray-800"
                                src="https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                alt="" />
                            <div class="text-base">
                                <div class="font-semibold text-white">Joseph Rodriguez</div>
                                <div class="mt-1 text-gray-400">CEO of Reform</div>
                            </div>
                        </figcaption>
                    </figure>
                </div>
            </div>
        </div>
    </section>

    <!-- Meet the team
    <div class="bg-white py-24 sm:py-32">
        <div class="mx-auto grid max-w-7xl gap-x-8 gap-y-20 px-6 lg:px-8 xl:grid-cols-3">
            <div class="max-w-2xl">
                <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Meet our team</h2>
                <p class="mt-6 text-lg leading-8 text-gray-600">We are building tools to provide analysts and
                    decision-makers
                    with reliable, ready to analyse data. </p>
            </div>
            <ul role="list" class="grid gap-x-8 gap-y-12 sm:grid-cols-2 sm:gap-y-16 xl:col-span-2">
                <li v-for="member in teamMembers" :key="member.name">
                    <div class="flex items-center gap-x-6">
                        <img class="h-24 w-24 rounded-full" :src="member.imageUrl" :alt="member.name">
                        <div>
                            <h3 class="text-base font-semibold leading-7 tracking-tight text-gray-900">{{ member.name }}</h3>
                            <p class="text-sm font-semibold leading-6 text-indigo-600">{{ member.role }}</p>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
    -->

    <!-- Get Started  Final Call Out-->
    <div class="bg-slate-100">
        <div class="px-6 py-24 sm:px-6 sm:py-32 lg:px-8">
            <div class="mx-auto max-w-2xl text-center">
                <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    Ready to Transform Your Business?
                </h2>
                <p class="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600">
                    Get in touch with us today. Schedule your free consultation
                </p>
                <div class="mt-10 flex items-center justify-center gap-x-6">
                    <a
                        class="rounded-md bg-slate-900 py-3 sm:py-4 px-8 sm:px-12 text-md font-semibold text-white shadow-sm hover:bg-slate-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:bg-slate-800 lightbox-242710848738061">
                        Share Your Automation Idea
                    </a>
                    <!-- <a href="#" class="text-sm font-semibold leading-6 text-gray-900">
                        Watch Demo <span  aria-hidden="true">→</span>
                    </a> -->
                </div>
            </div>
        </div>
    </div>
</template>


<script>
/* global JotformFeedback */ // Declare JotformFeedback as a global variable

import { CheckIcon } from '@heroicons/vue/20/solid'

export default {
    name: 'HomePage',
    components: {
        CheckIcon
    },
    data() {
        return {
            backgroundImage: require('@/assets/vendo-integrations.png'),
            features: [
                { name: 'Customer Service Automation', description: 'Enhance customer interactions with AI-driven chatbots and virtual assistants, providing 24/7 support and personalized responses.' },
                { name: 'Data Analysis and Insights', description: 'Utilize AI to analyze large datasets, uncover patterns, and generate actionable insights for strategic decision-making.' },
                { name: 'Marketing Automation', description: 'Automate marketing campaigns with AI to target the right audience, optimize ad spend, and increase conversion rates.' },
                { name: 'Sales Assistance', description: 'Empower your sales team with AI tools that provide lead scoring, predictive analytics, and personalized sales strategies.' },
                { name: 'Inventory Management', description: 'Optimize stock levels and reduce waste with AI-powered demand forecasting and automated replenishment systems.' },
                { name: 'Supply Chain Optimization', description: 'Streamline supply chain operations with AI to improve efficiency, reduce costs, and enhance supplier relationships.' },
                { name: 'Predictive Maintenance', description: 'Prevent equipment failures and reduce downtime with AI-driven predictive maintenance solutions.' },
                { name: 'Fraud Detection', description: 'Protect your business with AI algorithms that detect and prevent fraudulent activities in real-time.' },
                { name: 'Personalized Recommendations', description: 'Increase customer engagement with AI-powered recommendation engines that deliver personalized product suggestions.' },
                { name: 'Scheduling and Calendar Management', description: 'Automate scheduling tasks with AI to optimize time management and improve productivity.' },
                { name: 'Human Resources Automation', description: 'Streamline HR processes with AI, from recruitment and onboarding to performance management and employee engagement.' },
                { name: 'Document Processing', description: 'Automate document handling with AI to improve accuracy, speed, and compliance in data processing.' },
                { name: 'Email Automation', description: 'Enhance communication efficiency with AI-driven email automation for personalized and timely responses.' },
                { name: 'Financial Forecasting', description: 'Leverage AI to predict financial trends, optimize budgeting, and improve financial planning accuracy.' },
                { name: 'Competitive Intelligence', description: 'Gain a competitive edge with AI tools that analyze market trends and competitor strategies.' },
                { name: 'Quality Control', description: 'Ensure product quality with AI-powered inspection systems that detect defects and ensure compliance.' },
                { name: 'Voice Assistants', description: 'Integrate AI voice assistants to improve user interaction and accessibility across platforms.' },
                { name: 'Compliance Monitoring', description: 'Automate compliance checks with AI to ensure adherence to regulations and reduce risk.' },
                { name: 'Risk Assessment', description: 'Utilize AI to identify and mitigate potential risks, enhancing business resilience and security.' },
                { name: 'Social Media Management', description: 'Optimize social media presence with AI tools that automate content scheduling, engagement, and analytics.' },
            ],
            steps: [
                { name: 'Initial Consultation', description: 'Discuss your business needs and automation goals.', href: '', status: 'current' }, //or can be complete
                { name: 'Proposal Delivery', description: 'Receive a customized plan outlining our AI solutions.', href: '', status: 'upcoming', },
                { name: 'Development', description: 'We build and test your custom AI agents.', href: '', status: 'upcoming' },
                { name: 'Integration', description: 'Seamless integration into your existing systems.', href: '', status: 'upcoming' },
                { name: 'Ongoing Support', description: 'Continuous monitoring and optimization.', href: '', status: 'upcoming' },
            ],
            teamMembers: [
                {
                    name: 'Yalcin Kaya',
                    role: 'Co-Founder / Business Consultant',
                    imageUrl: 'https://media.licdn.com/dms/image/v2/C4D03AQGIz-px49vbcQ/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1516804766926?e=1730332800&v=beta&t=HZKDnb4kVH3nNhNtDROKjOuJKwQ99avFpzbC-AyIWlg'
                },
                /* {
                     name: 'Naveen Gupta',
                     role: 'Business Analyst',
                     imageUrl: 'https://i.pravatar.cc/250?img=68'
                 },
                 {
                     name: 'Soumya Bhatt',
                     role: 'AI Engineer',
                     imageUrl: 'https://i.pravatar.cc/250?img=48'
                 },
                 {
                     name: 'Vivek Bhusal',
                     role: 'AI Engineer',
                     imageUrl: 'https://i.pravatar.cc/250?img=59'
                 },
                 {
                     name: 'Onur Unlu',
                     role: 'Data Engineer',
                     imageUrl: 'https://i.pravatar.cc/250?img=52'
                 }*/
            ]
        }
    },
    mounted() {
        // Load Jotform scripts
        const jotformScript1 = document.createElement('script');
        jotformScript1.src = "https://form.jotform.com/static/feedback2.js";
        jotformScript1.type = "text/javascript";
        document.head.appendChild(jotformScript1);

        const jotformScript2 = document.createElement('script');
        jotformScript2.src = 'https://cdn.jotfor.ms/s/umd/latest/for-form-embed-handler.js';
        document.head.appendChild(jotformScript2);

        jotformScript1.onload = () => {
            new JotformFeedback({
                formId: '242710848738061',
                base: 'https://form.jotform.com/',
                windowTitle: 'AI Solutions Enquiry',
                backgroundColor: '#FFA500',
                fontColor: '#FFFFFF',
                type: 'false',
                height: 500,
                width: 700,
                openOnLoad: false
            });
        };

        jotformScript2.onload = () => {
            window.jotformEmbedHandler("iframe[id='242710848738061']", "https://form.jotform.com/");
        };
    }
}
</script>

<!-- Add this CSS to your <style> section or a separate CSS file -->
<style>
    .code-block {
        font-family: 'Courier New', Courier, monospace;
        white-space: pre-wrap; /* Ensures the code wraps */
    }
</style>